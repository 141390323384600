import * as React from "react"
import {  graphql } from 'gatsby'

import TrainingPageTemplate from "../components/pageTemplates/trainingPageOverview.js"
 
// markup
const TrainingPage = ({ props, data, pageContext }) => {
  var locale = 'en'

  return ( 
    <TrainingPageTemplate data={data} 
                          locale={locale}
                          pageContext={pageContext} />
  )
}

export const query = graphql`
query Trainings_en{
  allAirtableTrainings {
    nodes {
      data {
        Excerpt {
          childMdx {
            body
          }
        }
        Locale
        Title
        Slogan 
        Slug
      }
    }    
  }
} 
`
export default TrainingPage

